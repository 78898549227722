import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Header } from '../../components/Header/Header';
import BlogList from '../../components/BlogList/BlogList';

const BlogPostTemplate = ({ pageContext, data, location, siteTitle }) => {
  const { tag } = pageContext
  const { edges } = data.allMarkdownRemark;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Tag" />
      <Header />
      <div className="text-center mt-3">
        <h3 className="text-primary-color">Tag: {tag}</h3>
      </div>
      <BlogList posts={edges} postsInIndexPage={1000} />
    </Layout>
  )
}
export default BlogPostTemplate;
export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
        postsInIndexPage
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            cover {
              publicURL
            }
          }
        }
      }
    }
  }
`;